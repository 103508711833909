<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Resumo de Correções</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Dashboard v1</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Redações</h3>
                        <div class="card-tools">
                            <a href="#" class="btn btn-tool btn-sm">
                                <i class="fas fa-download"></i>
                            </a>
                            <a href="#" class="btn btn-tool btn-sm">
                                <i class="fas fa-bars"></i>
                            </a>
                        </div>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <thead>
                                <tr>
                                    <th>Flags</th>
                                    <th>Tipo de Correção</th>
                                    <th>Número de Correções</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="correction in corrections" :key="correction.id">
                                    <td>
                                        <small class="badge bg-black mr-1" v-if="correction.ia_correction">IA</small>
                                        <small class="badge bg-danger" v-if="correction.med">Med</small>
                                    </td>
                                    <td>{{ correction.essay_type }}</td>
                                    <td>{{ correction.corrected }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="col-md-2 info-box mb-3 bg-success d-flex justify-content-center">
                        <span class="info-box-icon">
                            <font-awesome-icon icon="clipboard-check" class="nav-icon" />
                        </span>
                        <div class="info-box-content text-center">
                            <span class="info-box-text"><b>Total de Correções</b></span>
                            <h2 class="info-box-number">{{ this.corrections.reduce((accumulator, current) => accumulator + current.corrected, 0) }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Index',
    data() {
        return  {
            corrections: []
        }
    },
}
</script>